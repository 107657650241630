<template>
  <div id="errorLayout" class="asf-error-page">
    <AsfError :status-code="error?.statusCode" />
  </div>
</template>

<script lang="ts" setup>
import type { AsfListOfStatusCodes } from '@ui/types/components/pages'

type Props = {
  error: {
    statusCode: AsfListOfStatusCodes
  }
}
defineProps<Props>()
definePageMeta({
  layout: 'empty'
})
const { reportPageView } = useTagManager()
reportPageView('error')
</script>
